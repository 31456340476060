let Logindialog;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBE4jvTTaRNKN0iXNd_B_AP4Xrwl4EXqYs",
  authDomain: "dedupeone.firebaseapp.com",
  databaseURL: "https://dedupeone-default-rtdb.firebaseio.com",
  projectId: "dedupeone",
  storageBucket: "dedupeone.appspot.com",
  messagingSenderId: "939637555230",
  appId: "1:939637555230:web:615611b6fa04962e56ae0f",
  measurementId: "G-JDNL35CYD1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Define function to initiate sign-in process
function signIn() {
  var accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    // If access token already exists, use it directly
    getEmail();
  } else {
    var url =
      "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=token&client_id=a3d2e644-7e2e-4e73-9939-ab459ab1e901&redirect_uri=https://www.numbertowordsexcel.com/assets/redirectPage.html&scope=openid+profile+offline_access+User.Read&state=12345";

    Office.context.ui.displayDialogAsync(url, { height: 70, width: 50 }, function (asyncResult) {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        Logindialog = asyncResult.value;
        Logindialog.addEventHandler(Office.EventType.DialogMessageReceived, processMessage);
      } else {
        console.error("Error opening dialog:", asyncResult.error);
      }
    });
  }
}

// Define function to process message received from dialog
function processMessage(arg) {
  var accessToken = arg.message;
  localStorage.setItem("accessToken", accessToken); // Store access token for future use
  Logindialog.close();
  // Now that you have the access token, you can use it to fetch user's information
  getEmail();
}

// Define function to fetch user's email using the access token
function getEmail() {
  var accessToken = localStorage.getItem("accessToken");
  var userEmail = localStorage.getItem("userEmail");

  if (accessToken && userEmail) {
    // If both access token and user email exist in local storage, no need to fetch again
    // Check if user email already exists in Firebase
    checkIfEmailExistsInFirebase(userEmail);
    return;
  }

  fetch("https://graph.microsoft.com/v1.0/me", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      // Extract email from user data
      var userEmail = data.mail; // Assuming 'data.mail' contains the user's email

      // Set user email in local storage
      localStorage.setItem("userEmail", userEmail);

      // Check if user email already exists in Firebase
      checkIfEmailExistsInFirebase(userEmail);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
}

// Define function to check if user's email exists in Firebase
function checkIfEmailExistsInFirebase(userEmail) {
  const userDocRef = doc(db, "payments", "numbertowords");
  getDoc(userDocRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        if (!userData[userEmail]) {
          // Email doesn't exist, proceed to set it with -1 date
          setUserEmailInFirebase(userEmail);
        } else {
          const myHeading = document.getElementById("myHeading");
          myHeading.innerHTML = userEmail;

          // Email already exists, proceed without modifying
          document.getElementById("welcomePage").style.display = "none";
          document.getElementById("content").style.display = "block";
        }
      } else {
        // Document doesn't exist, proceed to set email
        setUserEmailInFirebase(userEmail);
      }
    })
    .catch((error) => {
      console.error("Error checking if email exists in Firebase:", error);
    });
}

// Define function to set user's email in Firebase with -1 date
function setUserEmailInFirebase(userEmail) {
  // Get today's date
  const today = new Date();
  // Calculate yesterday's date
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  // Convert yesterday's date to string in the format "YYYY-MM-DD"
  const yesterdayString = yesterday.toISOString().split("T")[0];

  // Store email and date in Firestore
  const userDocRef = doc(db, "payments", "numbertowords");

  getDoc(userDocRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        userData[userEmail] = yesterdayString; // Add new email and date to existing data
        setDoc(userDocRef, userData)
          .then(() => {
            // Hide the sign-in button and show the content
            const myHeading = document.getElementById("myHeading");
            myHeading.innerHTML = userEmail;
            document.getElementById("welcomePage").style.display = "none";
            document.getElementById("content").style.display = "block";
          })
          .catch((error) => {
            console.error("Error storing email and date: ", error);
          });
      } else {
        // If the document doesn't exist yet, create a new one with only the new email
        const userData = {};
        userData[userEmail] = yesterdayString;
        setDoc(userDocRef, userData)
          .then(() => {
            // Hide the sign-in button and show the content
            const myHeading = document.getElementById("myHeading");
            myHeading.innerHTML = userEmail;
            document.getElementById("welcomePage").style.display = "none";
            document.getElementById("content").style.display = "block";
          })
          .catch((error) => {
            console.error("Error storing email and date: ", error);
          });
      }
    })
    .catch((error) => {
      console.error("Error getting document:", error);
    });
}

// The initialize function must be run each time a new page is loaded
Office.onReady(() => {
  var accessToken = localStorage.getItem("accessToken");
  var userEmail = localStorage.getItem("userEmail");
  if (accessToken && userEmail) {
    // If both access token and user email exist in local storage, check if email exists in Firebase
    checkIfEmailExistsInFirebase(userEmail);
  } else {
    // If either access token or user email does not exist, show the sign-in button
    document.getElementById("welcomePage").style.display = "block";
    document.getElementById("content").style.display = "none";
    document.getElementById("signin-button").addEventListener("click", signIn);
  }
});
// Get the button element by its ID
const upgradeButton = document.getElementById("upgradeButton");

// Add click event listener to the button
upgradeButton.addEventListener("click", redirectToPayment);
// Function to redirect to the payment link
function redirectToPayment() {
  window.location.href = "https://www.paypal.com/webapps/billing/plans/subscribe?plan_id=P-710222665X822423SMX5VAZQ";
}

const signoutbtn = document.getElementById("signoutbtn");

signoutbtn.addEventListener("click", signOut);

function signOut() {
  localStorage.removeItem("userEmail");
  localStorage.removeItem("accessToken");
  window.location.reload();
}
